var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user_role.toLowerCase() === 'atasan keuangan')?_c('div',[_c('b-dropdown',{staticClass:"px-2 mr-4",attrs:{"size":"m","variant":"link","toggle-class":"topbar-item text-decoration-none","no-caret":"","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('notification-bell',{attrs:{"size":20,"count":_vm.totalNotification,"ding":true,"upperLimit":50,"counterLocation":"upperRight","counterStyle":"roundRectangle","counterBackgroundColor":"#FF0000","counterTextColor":"#FFFFFF","iconColor":"#1e2758","animated":false,"fontSize":"120"}})]},proxy:true}],null,false,2808225772)},[_c('b-dropdown-text',{staticClass:"min-w-md-350px",attrs:{"tag":"div"}},[_c('form',[_c('b-tabs',[_c('b-tab',[_c('perfect-scrollbar',{staticClass:"navi navi-hover scroll my-4",staticStyle:{"max-height":"40vh","position":"relative"}},[(_vm.ctr_kas_kecil_masuk > 0)?_c('router-link',{attrs:{"to":{
              name: 'shortcut-cashbank-in',
              params: {
                param_approval_status: '0'
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return _vm._l((_vm.data_kas_kecil_masuk),function(i){return _c('a',{key:i,staticClass:"navi-item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2"},[_c('i',{staticClass:"flaticon2-bell"})]),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Kas Kecil Masuk ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(i.no_voucher)+" ")])])])])})}}],null,false,341154398)}):_vm._e(),(_vm.ctr_kas_kecil_keluar > 0)?_c('router-link',{attrs:{"to":{
              name: 'shortcut-cashbank-out',
              params: {
                param_approval_status: '0'
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return _vm._l((_vm.data_kas_kecil_keluar),function(i){return _c('a',{key:i,staticClass:"navi-item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2"},[_c('i',{staticClass:"flaticon2-bell"})]),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Kas Kecil Keluar Internal ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(i.no_voucher)+" ")])])])])})}}],null,false,2771003289)}):_vm._e(),(_vm.ctr_kas_kecil_keluar_operasional > 0)?_c('router-link',{attrs:{"to":{
              name: 'shortcut-cashbank-out-ops',
              params: {
                param_approval_status: '0'
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return _vm._l((_vm.data_kas_kecil_keluar_operasional),function(i){return _c('a',{key:i,staticClass:"navi-item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2"},[_c('i',{staticClass:"flaticon2-bell"})]),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Kas Kecil Keluar Operasional ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(i.no_voucher)+" ")])])])])})}}],null,false,652244641)}):_vm._e(),(_vm.ctr_kas_kecil_keluar_adm > 0)?_c('router-link',{attrs:{"to":{
              name: 'shortcut-cashbank-out-adm',
              params: {
                param_approval_status: '0'
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return _vm._l((_vm.data_kas_kecil_keluar_adm),function(i){return _c('a',{key:i,staticClass:"navi-item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2"},[_c('i',{staticClass:"flaticon2-bell"})]),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Kas Kecil Keluar Admin ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(i.no_voucher)+" ")])])])])})}}],null,false,4091330182)}):_vm._e(),(_vm.ctr_bgoi > 0)?_c('router-link',{attrs:{"to":{
              name: 'shortcut-bgoi',
              params: {
                param_approval_status: '0'
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return _vm._l((_vm.data_bgoi),function(i){return _c('a',{key:i,staticClass:"navi-item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2"},[_c('i',{staticClass:"flaticon2-bell"})]),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" BG Internal ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(i.submission_number)+" ")])])])])})}}],null,false,4252647845)}):_vm._e(),(_vm.ctr_bgoe > 0)?_c('router-link',{attrs:{"to":{
              name: 'shortcut-bgoe',
              params: {
                param_approval_status: '0'
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return _vm._l((_vm.data_bgoe),function(i){return _c('a',{key:i,staticClass:"navi-item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2"},[_c('i',{staticClass:"flaticon2-bell"})]),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" BG Eksternal Keluar ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(i.submission_number)+" ")])])])])})}}],null,false,2080353591)}):_vm._e(),(_vm.ctr_bgoe_masuk > 0)?_c('router-link',{attrs:{"to":{
              name: 'shortcut-bgoe-masuk',
              params: {
                param_approval_status: '0'
              }
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return _vm._l((_vm.data_bgoe_masuk),function(i){return _c('a',{key:i,staticClass:"navi-item",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2"},[_c('i',{staticClass:"flaticon2-bell"})]),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" BG Eksternal Masuk ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(i.submission_number)+" ")])])])])})}}],null,false,1698755212)}):_vm._e()],1)],1)],1)],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }