<template>
  <div v-if="user_role.toLowerCase() === 'atasan keuangan'">
  <b-dropdown
    size="m"
    variant="link"
    toggle-class="topbar-item text-decoration-none"
    no-caret
    right
    no-flip
    class="px-2 mr-4"
  >
  <template v-slot:button-content>
    <notification-bell
      :size="20"
      :count="totalNotification"
      :ding="true"
      :upperLimit="50"
      counterLocation="upperRight"
      counterStyle="roundRectangle"
      counterBackgroundColor="#FF0000"
      counterTextColor="#FFFFFF"
      iconColor="#1e2758"
      :animated="false"
      fontSize="120"
    />
  </template>
  <b-dropdown-text tag="div" class="min-w-md-350px">
    <form>
      <b-tabs>
        <b-tab>
          <perfect-scrollbar
            class="navi navi-hover scroll my-4"
            style="max-height: 40vh; position: relative;"
          >
            <router-link
              :to="{
                name: 'shortcut-cashbank-in',
                params: {
                  param_approval_status: '0'
                }
              }"
              v-slot="{ href, navigate }"
              v-if="ctr_kas_kecil_masuk > 0"
            >
              <a
                :href="href"
                class="navi-item"
                v-for="i in data_kas_kecil_masuk"
                v-bind:key="i"
                @click="navigate"
              >
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-bell"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">
                      Kas Kecil Masuk
                    </div>
                    <div class="text-muted">                      
                      {{i.no_voucher}}
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link
              :to="{
                name: 'shortcut-cashbank-out',
                params: {
                  param_approval_status: '0'
                }
              }"
              v-slot="{ href, navigate }"
              v-if="ctr_kas_kecil_keluar > 0"
            >
              <a
                :href="href"
                class="navi-item"
                v-for="i in data_kas_kecil_keluar"
                v-bind:key="i"
                @click="navigate"
              >
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-bell"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">
                      Kas Kecil Keluar Internal                   
                    </div>
                    <div class="text-muted">
                       {{i.no_voucher}}
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link
              :to="{
                name: 'shortcut-cashbank-out-ops',
                params: {
                  param_approval_status: '0'
                }
              }"
              v-slot="{ href, navigate }"
              v-if="ctr_kas_kecil_keluar_operasional > 0"
            >
              <a
                :href="href"
                class="navi-item"
                v-for="i in data_kas_kecil_keluar_operasional"
                v-bind:key="i"
                @click="navigate"
              >
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-bell"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">
                      Kas Kecil Keluar Operasional
                    </div>
                    <div class="text-muted">
                       {{i.no_voucher}}
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link
              :to="{
                name: 'shortcut-cashbank-out-adm',
                params: {
                  param_approval_status: '0'
                }
              }"
              v-slot="{ href, navigate }"
              v-if="ctr_kas_kecil_keluar_adm > 0"
            >
              <a
                :href="href"
                class="navi-item"
                v-for="i in data_kas_kecil_keluar_adm"
                v-bind:key="i"
                @click="navigate"
              >
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-bell"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">
                      Kas Kecil Keluar Admin
                    </div>
                    <div class="text-muted">
                       {{i.no_voucher}}
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link
              :to="{
                name: 'shortcut-bgoi',
                params: {
                  param_approval_status: '0'
                }
              }"
              v-slot="{ href, navigate }"
              v-if="ctr_bgoi > 0"
            >
              <a
                :href="href"
                class="navi-item"
                v-for="i in data_bgoi" 
                v-bind:key="i"
                @click="navigate"
              >
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-bell"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">
                      BG Internal
                    </div>
                    <div class="text-muted">
                       {{i.submission_number}}
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link
              :to="{
                name: 'shortcut-bgoe',
                params: {
                  param_approval_status: '0'
                }
              }"
              v-slot="{ href, navigate }"
              v-if="ctr_bgoe > 0"
            >
              <a
                :href="href"
                class="navi-item"
                v-for="i in data_bgoe"
                v-bind:key="i"
                @click="navigate"
              >
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-bell"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">
                      BG Eksternal Keluar
                    </div>
                    <div class="text-muted">
                       {{i.submission_number}}
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link
              :to="{
                name: 'shortcut-bgoe-masuk',
                params: {
                  param_approval_status: '0'
                }
              }"
              v-slot="{ href, navigate }"
              v-if="ctr_bgoe_masuk > 0"
            >
              <a
                :href="href"
                class="navi-item"
                v-for="i in data_bgoe_masuk"
                v-bind:key="i"
                @click="navigate"
              >
                <div class="navi-link">
                  <div class="navi-icon mr-2">
                    <i class="flaticon2-bell"></i>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">
                      BG Eksternal Masuk 
                    </div>
                    <div class="text-muted">
                       {{i.submission_number}}
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
          </perfect-scrollbar>
        </b-tab>
      </b-tabs>
    </form>
  </b-dropdown-text>
  </b-dropdown>
  </div>
</template>
<script>

import NotificationBell from 'vue-notification-bell';
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import localStorage from "@/core/services/store/localStorage";

export default {
  name: 'ApprovalNotification',
  // ...
  components: {
    NotificationBell // Registering Component
  },  

  created() {
    this.user_role = localStorage.getLocalStorage("User_Role");
    if (this.user_role.toLowerCase() === 'atasan keuangan'){
      this.load();
    }    
  },

  data: () => ({
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],     
    user_role: "",

    data_kas_kecil_masuk: [], data_kas_kecil_keluar: [], data_kas_kecil_keluar_operasional: [], data_kas_kecil_keluar_adm: [],
    data_bgoi: [], data_bgoe: [], data_bgoe_masuk: [], 
    ctr_kas_kecil_masuk: 0, ctr_kas_kecil_keluar: 0, ctr_kas_kecil_keluar_operasional: 0,  ctr_kas_kecil_keluar_adm: 0,
    ctr_bgoi: 0, ctr_bgoe: 0, ctr_bgoe_masuk: 0
  }),

  methods: {
    _KasKecilMasuk() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          cashbank_type: "KKM",
          cashbank_permission: "U",
          approval_status: 0,
          special_cashbank_desc: "atasan keuangan" 
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_kas_kecil_masuk = response.data;
            this.ctr_kas_kecil_masuk = response.data.length;
          },
          err => {
            err;
          }
        );
      });
    },    

    _KasKecilKeluar() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          cashbank_type: "KKK",
          cashbank_permission: "I",
          approval_status: 0,
          special_cashbank_desc: "atasan keuangan"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_kas_kecil_keluar = response.data;
            this.ctr_kas_kecil_keluar = response.data.length;
          },
          err => {
            err;
          }
        );
      });
    },  
    
    _KasKecilKeluarOperasional() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          cashbank_type: "KKK",
          cashbank_permission: "O",
          approval_status: 0,
          special_cashbank_desc: "atasan keuangan"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_kas_kecil_keluar_operasional = response.data;
            this.ctr_kas_kecil_keluar_operasional = response.data.length;
          },
          err => {
            err;
          }
        );
      });
    },

    _KasKecilKeluarAdmin() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          cashbank_type: "KKK",
          cashbank_permission: "AP",
          approval_status: 0,
          special_cashbank_desc: "atasan keuangan"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_kas_kecil_keluar_adm = response.data;
            this.ctr_kas_kecil_keluar_adm = response.data.length;
          },
          err => {
            err;
          }
        );
      });
    },

    _BGOI() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_BANK_GUARANTEE",
          submission_type: "BGOI",
          submission_permission: "OI",
          approval_status: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bgoi = response.data;
            this.ctr_bgoi = response.data.length;
          },
          err => {
            err;
          }
        );
      });
    },

    _BGOE() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_BANK_GUARANTEE",
          submission_type: "BGOE",
          submission_permission: "OI",
          approval_status: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bgoe = response.data;
            this.ctr_bgoe = response.data.length;
          },
          err => {
            err;
          }
        );
      });
    },

    _BGOE_Masuk() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_BANK_GUARANTEE",
          submission_type: "BGOE",
          submission_permission: "OE",
          approval_status: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bgoe_masuk = response.data;
            this.ctr_bgoe_masuk = response.data.length;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([
        await this._KasKecilMasuk(),
        await this._KasKecilKeluar(),
        await this._KasKecilKeluarOperasional(),
        await this._KasKecilKeluarAdmin(),
        await this._BGOI(),
        await this._BGOE(),
        await this._BGOE_Masuk(),
      ]).then(function(results) {
        results;
      });
    }
  },

  computed: {
    totalNotification: function() {
      let _ctr_kas_kecil_masuk = this.ctr_kas_kecil_masuk;
      let _ctr_kas_kecil_keluar = this.ctr_kas_kecil_keluar;
      let _ctr_kas_kecil_keluar_operasional = this.ctr_kas_kecil_keluar_operasional;
      let _ctr_kas_kecil_keluar_adm = this.ctr_kas_kecil_keluar_adm;
      let _ctr_bgoi = this.ctr_bgoi;
      let _ctr_bgoe = this.ctr_bgoe;
      let _ctr_bgoe_masuk = this.ctr_bgoe_masuk;

      let totalNotification = 0;

      totalNotification += parseInt(
        _ctr_kas_kecil_masuk + _ctr_kas_kecil_keluar + _ctr_kas_kecil_keluar_operasional + _ctr_kas_kecil_keluar_adm +
        _ctr_bgoi + _ctr_bgoe + _ctr_bgoe_masuk
      );

      return totalNotification;
    }
  }
  // ...
}
</script>